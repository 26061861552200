import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDiseases(ctx, data) {
      return useJwt.getDiseases(data).then(response => response);
    },
    createDisease(ctx, data) {
      return useJwt.createDisease(data).then(response => response);
    },
    updateDisease(ctx, data) {
      return useJwt.updateDisease(data).then(response => response);
    },
    deleteDisease(ctx, id) {
      return useJwt.deleteDisease(id).then(response => response);
    },
  },
};
