<template>
  <b-modal
    id="modal-disease"
    ref="refModal"
    title="Bệnh tật"
    ok-title="Lưu"
    cancel-title="Huỷ"
    cancel-variant="outline-secondary"
    size="lg"
    scrollable
    :hide-header-close="true"
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >

        <b-row>
          <b-col cols="6">
            <validation-provider
              #default="validationContext"
              name="code"
              rules=""
            >
              <b-form-group>
                <template v-slot:label>
                  Mã bệnh
                </template>
                <b-form-input
                  id="code"
                  v-model="itemLocal.code"
                  placeholder=""
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <validation-provider
              #default="validationContext"
              name="name"
              rules="required"
            >
              <b-form-group>
                <template v-slot:label>
                  Tên bệnh <span class="text-danger">(*)</span>
                </template>
                <b-form-input
                  id="name"
                  v-model="itemLocal.name"
                  placeholder=""
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="6">
            <validation-provider
              #default="validationContext"
              name="type_code"
              rules=""
            >
              <b-form-group>
                <template v-slot:label>
                  Mã loại
                </template>
                <b-form-input
                  id="type_code"
                  v-model="itemLocal.type_code"
                  placeholder=""
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="6">
            <validation-provider
              #default="validationContext"
              name="type"
              rules=""
            >
              <b-form-group>
                <template v-slot:label>
                  Loại
                </template>
                <b-form-input
                  id="type"
                  v-model="itemLocal.type"
                  placeholder=""
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <validation-provider
              #default="validationContext"
              name="group_code"
              rules=""
            >
              <b-form-group>
                <template v-slot:label>
                  Mã nhóm
                </template>
                <b-form-input
                  id="group_code"
                  v-model="itemLocal.group_code"
                  placeholder=""
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <validation-provider
              #default="validationContext"
              name="group_name"
              rules=""
            >
              <b-form-group>
                <template v-slot:label>
                  Tên nhóm
                </template>
                <b-form-input
                  id="group_name"
                  v-model="itemLocal.group_name"
                  placeholder=""
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <validation-provider
              #default="validationContext"
              name="chapter_code"
              rules=""
            >
              <b-form-group>
                <template v-slot:label>
                  Mã chương
                </template>
                <b-form-input
                  id="chapter_code"
                  v-model="itemLocal.chapter_code"
                  placeholder=""
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <validation-provider
              #default="validationContext"
              name="chapter_name"
              rules=""
            >
              <b-form-group>
                <template v-slot:label>
                  Tên chương
                </template>
                <b-form-input
                  id="chapter_name"
                  v-model="itemLocal.chapter_name"
                  placeholder=""
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Triệu chứng lâm sàng">
              <b-form-textarea
                id="clinical_information"
                v-model="itemLocal.clinical_information"
                placeholder="Triệu chứng lâm sàng"
                rows="3"
              />
            </b-form-group>
          </b-col>

          <!-- submit -->
        </b-row>

      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormInput,
  BForm,
  BRow,
  BCol,
  BFormTextarea,
  VBTooltip,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import { togglePasswordConfirmVisibility } from '@/layouts/mixins/forms';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import useDiseaseModal from './useDiseaseModal';

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BForm,
    BCol,
    BRow,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [togglePasswordVisibility, togglePasswordConfirmVisibility],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    passwordConfirmToggleIcon() {
      return this.passwordConfirmFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModal,
      itemLocal,
      resetItemLocal,
      resetModal,

      onSubmit,
    } = useDiseaseModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      resetItemLocal,
      resetModal,
      onSubmit,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    };
  },
};
</script>
